import React from 'react'

const LoginButton = ({mobile=false}) => (
    <a 
    href="https://webapp.thegreeninvestmentclub.com/users/login"  
    className={(mobile ? "block": "hidden sm:inline-block absolute  lg:-right-40 md:-right-20 sm:-right-20")} 
    target="_blank" 
    rel="noreferrer">
        <button className={(mobile ? 'border mt-6':"border-2")+` font-display font-medium uppercase rounded-md  border-torq-400  bg-transparent py-2 px-8 text-torq-400`}>Login</button>
    </a>
)

export default LoginButton