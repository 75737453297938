import React,{useState} from "react"
import {Link} from 'gatsby'

import HomeLogo from "../../images/logo/tgic-home-logo.svg"
import Logo from "../../images/logo/tgic-logo.svg"
import MenuIcon from "../../images/icons/menu-icon.svg"
import CloseIcon from "../../images/icons/close-icon.svg"
import MenuItem from './menu-item'
import LoginButton from "./login-button"


const Header = ({ home=false, color }) => {
  const [menuOpen, setMenuOpen] = useState(false)

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }
  return (
    <header className={`pl-12 pr-8  uppercase py-8  flex items-center justify-center bg-`+color} >

        <section className="max-w-screen-xl relative mx-auto flex items-center">

        <nav className="max-w-screen-lg pc-nav  sm:flex justify-between items-center font-display font-medium  hidden">
          <MenuItem url="mission" name="Mission" />
          <MenuItem url="member-stories" name="Member Stories" />
          <div className="h-32 flex items-center">
          <MenuItem url="" name={<img src={home ? HomeLogo: Logo} className={`mb-0`} alt="The Green Investment Club Logo" />} />
          </div>
          
          <MenuItem url="join-us" name="Join us" />
          <MenuItem url="blog/1" name="Blog" />
        </nav>

        <LoginButton />
        </section>


        <section className="sm:hidden w-full flex justify-between items-start">
          <Link to="/"><img src={HomeLogo} className="w-16 mb-0 inline-block" alt="The Green Investment Club Logo" /></Link>
          <button className="outline-none" onClick={toggleMenu} onKeyDown={toggleMenu}><img src={MenuIcon} className="w-10 mb-0 inline-block" alt="Menu Icon"   /></button>
        </section>
  
        {/* Mobile Navigation */}
        <nav className={ (menuOpen ? 'sm:block block':'sm:hidden hidden') +' w-8/12 pt-6 pb-12 bg-opacity-95 px-8 bg-white fixed top-0  z-10 right-0'}>
          <button onClick={toggleMenu} onKeyDown={toggleMenu} className="block text-right w-full mb-10">
            <img src={CloseIcon} className="w-10 mb-0 inline-block" alt="The Green Investment Club Logo" />
          </button>
          
          <MenuItem url="mission" name="Mission" />
          <MenuItem url="member-stories" name="Member Stories" />
          <MenuItem url="join-us" name="Join us" />
          <MenuItem url="blog/1" name="Blog" />

          <LoginButton mobile={true} />
        </nav>
        
  
    </header>
  )
}



export default Header
