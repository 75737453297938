import React from 'react'
import Icon from './icon'

//Social Icons
import Facebook from "../../images/icons/facebook-white.svg"
import Twitter from "../../images/icons/twitter-white.svg"
import Instagram from "../../images/icons/instagram-white.svg"
import Linkedin from "../../images/icons/linkedIn-white.svg"


const SocialIcons = ({mobile=false}) => (
    <div className={(mobile ? "flex sm:hidden":"hidden sm:flex ")+` sm:w-full w-9/12 mt-16 justify-between`}>
            <Icon url="https://web.facebook.com/The-green-investment-club-106637517939684" name={Facebook} />
            <Icon url="https://www.instagram.com/thegreeninvestmentclub/" name={Instagram} />
            <Icon url="https://www.linkedin.com/company/the-green-investment-club/" name={Linkedin} />
            <Icon url="https://twitter.com/TGIClub" name={Twitter} />
    </div>
)

export default SocialIcons