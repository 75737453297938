import React from "react"
import Logo from "../../images/logo/tgic-logo.svg"
import SocialIcons from "./social-icons"
import Subscribe from "./subscribe"
import Disclaimer from "../../images/icons/disclaimer.svg"

const Footer = () => {
  return (
    <>
      <Subscribe />
      <footer className="bg-torq-300 pt-16 sm:pb-24 pb-8 px-14  ">
        <div className="max-w-screen-xl m-auto flex flex-col sm:flex-row justify-between">
          <div className="sm:w-2/12 w-full">
            <img className="sm:w-auto w-32 block" src={Logo} alt="TGIC" />
            <SocialIcons />
          </div>

          <article className="sm:w-7/12 w-full mt-8 sm:mt-0 flex text-white sm:flex-row flex-col  ">
            <header className="w-2/12 pt-8 mr-2 text-center">
              <img
                src={Disclaimer}
                className="sm:w-auto w-8"
                alt="Disclaimer icon"
              />
            </header>
            <content>
              <span className="font-bold">IMPORTANT DISCLAIMER:</span>
              <br />

              <p>
                Please be mindful of fake whatsApp identities, whatsApp groups,
                facebook groups or websites posing as The Green Investment Club
                or The TGI club. The Green investment Club does not operate any
                whatsApp groups or solicit for investment in any way or form
                from the public. Any message received soliciting for your money
                or promising returns in any way or form is false and should be
                reported to the police.
                {/* Please note that the only messaging platform we have a group on
                is Telegram. Any other group you find, on WhatsApp or any other
                platform that operates in our name or any close imitation, is a
                fraudulent setup that must be avoided and reported.{" "} */}
              </p>
              <p>
                For more enquiries:{" "}
                <a href="mailto:hello@thegreeninvestmentclub.com">
                  hello@thegreeninvestmentclub.com
                </a>
              </p>
            </content>
          </article>

          <SocialIcons mobile={true} />
        </div>
      </footer>
    </>
  )
}

export default Footer
