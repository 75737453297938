import React from "react"

const Input = ({ type, setValue, value, placeholder }) => {
  return (
    <input
      className="py-3 px-6 rounded-md sm:w-5/12 w-full sm:mb-0 mb-4  outline-none text-torq-400 placeholder-torq-400"
      type={type}
      value={value}
      onChange={setValue}
      placeholder={placeholder}
      required
    />
  )
}

export default Input
