import React,{useState} from 'react'
import Input from './input'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import SubscribeIcon from "../../images/icons/subscribe-icon.svg"
const Subscribe = () => {
    const [firstName, setFirstname] = useState("")
    const [email, setEmail] = useState("")
    // const [message, setMessage] = useState("")

    const doSubscription = (e) => {
        e.preventDefault()

        addToMailchimp(email, {
            FNAME: firstName
        }).then(data => {
            // if(data.result === "sucesss"){
                setEmail("")
                setFirstname("")
            // }
            alert(data.msg)
            // setMessage(data.msg)

        })
    }

    return(
        <div className="bg-torq-400 py-16 px-12 ">
            <div className="max-w-screen-xl m-auto flex flex-col sm:flex-row items-center">

            <p className="sm:w-3/12 w-full text-white sm:mb-0">
                Join the biggest <br/>investment club in Nigeria.
            </p>
            <div className="sm:w-9/12  w-full mb-0">
                <form className="flex justify-between flex-col sm:space-x-4 space-x-0 sm:flex-row mb-0" onSubmit={doSubscription}>
                    <Input type="text" placeholder="First name" setValue={(e)=>setFirstname(e.target.value)} value={firstName} />
                    <Input type="email" placeholder="Email address" setValue={(e)=>setEmail(e.target.value)} value={email} />

                    <button className="group sm:w-2/12 w-full bg-torq-200 rounded-md px-6 sm:px-0 outline-none py-3 cursor-pointer  overflow-hidden flex sm:justify-center justify-end items-center">
                        <img src={SubscribeIcon} className="transform sm:w-auto w-8 transition duration-750 block mb-0 group-hover:translate-x-3" alt="Subscribe" />
                    </button>

                </form>
            </div>
            </div>
        </div>
    )
}

export default Subscribe